<div class="view-head mb-4 ms-5">
    <h2 class="text-secondary"><i class="bi bi-binoculars-fill"></i> SmartSearch</h2>
    <span class="instr"> Enter a prompt at the bottom of the page to interactively query the catalog contents.
    </span>
</div>

<div class="kb-convo-log" #scroll>

    <div *ngFor="let qr of convoLog">
        <div class="row  mb-4">

            <div class="col-7 offset-1 user-query">
                <i class="bi bi-question-circle text-primary chat-pfp fs-5 me-4"></i>

                <span class="user-query-text ">{{qr.query}}</span>
            </div>
        </div>
        <div class="row kb-response">
            <div class="col-1 offset-1 text-center">
                <span class="kb-response-label hidden">Generated response</span>
                <i class="bi bi-arrow-return-right text-secondary chat-pfp fs-4"></i>
            </div>
            <div class="col-7 ">

                <div class="kb-rsp-text">{{qr.response.text}}</div>
                <div class="spinner-border m-3 text-info" [hidden]="qr.response.text" role="status">
                    <span class="visually-hidden"> Loading ... </span>
                </div>
                <div class="kb-rsp-citations " [hidden]="!qr.response.text">
                    <span class="label">Citations</span>

                    <ul>
                        <li class="kb-bib row" *ngFor="let dlso of qr.response.citations">
                            <div class="bib-detail" *ngIf="dlso.bib">
                                <i class="bi bi-journal-bookmark me-2"></i>
                                <!-- probably want dlso thumbnail here, not bib cover ?-->
                                <a [href]="'/app/work/'+dlso.bib!.id">
                                    <span class="bib-title">{{dlso.bib.title}}</span>
                                </a>
                                <span class="bib-author">{{dlso.bib.author}}</span>
                                
                            </div>
                            <span class="visually-hidden">{{dlso.uuid}}</span>
                            <div class="bib-snippet">
                                <span class="snippet-label">
                                    Relevant context:
                                </span>
                                <span class="snippet-content">
                                    {{dlso.snippet}}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</div>


<div class="fixed-bottom kb-query">
    <div class="mb-5 mt-3">

        <form (submit)="kbQuery(query.value)">
            <div class="row justify-content-center">
                <div class="col-6">
                    <input [(ngModel)]="userQuery" type="text" name="userQuery" class="form-control"
                        placeholder="Enter your query" #query>

                </div>
                <div class="col-2">
                    <button class="btn btn-primary" type="submit">Search</button>
                    <button class="btn btn-outline-danger ms-2" (click)="clearConvo()" type="button">
                        <i class="bi bi-trash3"></i>
                    </button>
                </div>
            </div>

        </form>
    </div>

</div>
