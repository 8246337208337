import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KbQueryComponent } from './kb-query/kb-query.component';
import { FormsModule } from '@angular/forms';

if( ! window.globalRefs ) window.globalRefs = {};
window.globalRefs.dls = [,
    KbQueryComponent
];

@NgModule({
  declarations: [
    KbQueryComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class DlsModule { }
