export interface KbBibRef {
    id: number,
    title?: string,
    author?: string,
}

export interface KbDlsoRef {
    uuid: string,
    snippet: string,
    bib?: KbBibRef
}

export interface KbResponse {
    text: string,
    citations: KbDlsoRef[]
}

export interface KbConvo {
    query: string,
    response: KbResponse
}

export function xformApiCitation(cit: any): KbDlsoRef {
    // note retrievedReferences is an array, we assume only first
    let s3Uri = cit?.retrievedReferences[0]?.location?.s3Location?.uri;
    let uuidMatch = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.exec(s3Uri);
    try{
        return {
            snippet: cit?.generatedResponsePart?.textResponsePart?.text,
            uuid: uuidMatch?.[0] ||''
         }
    } catch (e) {
        return { snippet: '', uuid: ''};
    }
}