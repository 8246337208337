import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { downgradeComponent, getAngularJSGlobal } from '@angular/upgrade/static';
import { FormControl } from '@angular/forms';

import { KbService } from '../kb.service';
import { KbConvo } from '../kb-convo';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-kb-query',
  templateUrl: './kb-query.component.html',
  styles: [
    `a { font-color: red; }
    .chat-pfp i{
        font-size: 1.4em;
    }
    .kb-convo-log {
        padding-bottom: 5em;
    }
    .kb-convo-log .user-query {
        border: 1px solid #ddd;
        border-radius: 12px 12px 12px 0;
        padding: 10px;
    }
    .snippet-label {
        font-style: italic;
    }
    .kb-query {
        padding-top: 10px;
        background-color: white;
    }
    `
  ]
})


export class KbQueryComponent {

    convoLog: KbConvo[] = this.kbService.convoLog;
  
    // add input so we can blank it  out on submit.

    @Input() userQuery! : string;
    @ViewChild('scroll', { static: true, read: ElementRef }) private scroll: any;

    // scrollUpdate: any // fix type

    constructor( private kbService: KbService) {}

    kbQuery(q:string) {
        let scrollUpdate = this.kbService.kbQuery(q);
        scrollUpdate.subscribe( () => {
            console.log(this.scroll);

            this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
// FIXME: This isn't currently working.
        })
        this.userQuery = '';
        return false;
    }
    clearConvo() {
        this.kbService.convoLog.length = 0;
    }

}


getAngularJSGlobal().module('kohapac')
    .directive('appKbQuery', downgradeComponent({ component: KbQueryComponent, propagateDigest: false }));
