
<span>

    <div class="my-account" ngbDropdown >
        <button class="login-button btn" ngbDropdownToggle type="button" tabindex="0">
            <i class="bi bi-person-circle mx-2"></i>
            <span *ngIf="user.id; else unauthBtnTxt">{{user.displayname||'User'}}</span>
            <ng-template #unauthBtnTxt>My account [log in]</ng-template>
            <span class="caret"></span>
        </button>
    
        <ul ngbDropdownMenu role="menu">
    
            <li ngbDropdownItem class="menu-user-dash">
                <a href="/app/me/dashboard" class="user-link">My Dashboard</a>
            </li>
            <li ngbDropdownItem class="menu-lists" *ngIf="config.virtualshelves">
              <a href (click)="listDlgOpen()" ><span *ngIf="user.loggedin">My </span><span *ngIf="!user.loggedin">Public </span><span [innerHtml]="config.wording.lists"></span></a>
            </li>

            <li class="dropdown-divider"></li>
            <li ngbDropdownItem class="menu-set-login" *ngIf="user.is_staff && config.allowStaffBranchChange">
                <a class="clickable user-link" (click)="setLoginBranch()">Change Location</a>
            </li>
            <!-- <li ngbDropdownItem ng-repeat="m in socialMediaMethods" *ngIf="!user.loggedin && socialLogin" >
                <a href (click)="loginSocial(m.type)">
                    <button class="btn btn-social-icon btn-xs btn-{{::m.type}}"><i class="fa fa-{{::m.type}}"></i></button>
                    {{m.name}} Login
                </a>
            </li> -->
            <li ngbDropdownItem class="menu-exit-proxy" *ngIf="user.origin_userid">
                <a href="#" (click)="user.exitProxy()">Exit Proxy Session</a>
            </li>
            <li ngbDropdownItem class="menu-login" *ngIf="!user.loggedin && samlRedirectLogin">
                <a href (click)="samlRedirectLogin()">Log in</a>
            </li>
            <li ngbDropdownItem class="menu-staff-login" *ngIf="!user.loggedin && samlRedirectLogin && samlAllowMenuLocalLogin">
                <a href (click)="loginDlgOpen()">Staff Login</a>
            </li>
            <li ngbDropdownItem class="menu-login" *ngIf="!user.loggedin && !samlRedirectLogin">
                <a href (click)="loginDlgOpen()">Log in</a>
            </li>
            <li ngbDropdownItem class="menu-forgot-password" *ngIf="!user.loggedin && config.OpacPasswordChange">
                <a href (click)="lostPass()">Forgot Password?</a>
            </li>
            <li ngbDropdownItem class="menu-logout" *ngIf="user.loggedin && config.allowLogout && !samlRedirectLogout">
                <a href class="logout" (click)="user.logout()">Logout</a>
            </li>
            <li ngbDropdownItem class="menu-logout" *ngIf="user.loggedin && config.allowLogout && samlRedirectLogout">
                <a href class="logout" (click)="samlRedirectLogout()">Logout</a>
            </li>
            <li ngbDropdownItem class="menu-logout" *ngIf="user.loggedin && config.allowLogout && samlRedirectLogout && samlAllowMenuLocalLogout">
                <a href class="logout" (click)="user.logout()">Logout (Bibliovation)</a>
            </li>
        </ul>
    </div>
</span>
