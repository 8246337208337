import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KbConvo, KbResponse, xformApiCitation } from './kb-convo';
import { AlertService } from '../core/alerts/alert.service';
import { Observable, Subject, catchError, map, of, tap } from 'rxjs';
import { KOHA_MODEL_SVC } from '../upgrade/ng1-providers';

@Injectable({
    providedIn: 'root'
})
export class KbService {

    // apiUrl = "/api/kb";

    convoLog: KbConvo[] = []; // FIXME: this should be an observable in order to allow scroll updates in component.

    constructor(
        private http: HttpClient,
        private alertSvc: AlertService,
        @Inject(KOHA_MODEL_SVC) private kwApi: any
    ) { }

    kbQuery(q: string) : Subject<number> {
        // returns an observable for purpose of scrolling view on new data.
        let watcher = new Subject<number>();

        console.log(`submitting string :  ${q}` );
        let response:KbResponse = { text: '', citations: [] };
        this.convoLog.push( { query: q, response: response });

// console.log(this.apiUrl);
console.log(this);
        this.http.post<any>( '/api/knowledge-base' , { prompt: q } )
        .pipe(
            tap(_ => console.log('posted.')),
            map( data => {
                return {
                    text: data.output.text,
                    citations: data.citations.map( (cit:any) => {
                        return xformApiCitation(cit);
                    })}
            }),
            catchError( this.handleError<KbResponse>('kbQuery', { text: '', citations: [] }))
        )
        .subscribe( ( data ) => {
            console.log(data);
            // phew.  got it.
            // this.convoLog.push( {query: q, response: data });
            response.citations = data.citations;
            response.text = data.text;
            response.citations.forEach( (dlsoref) => {
                console.log(dlsoref);
                this.kwApi.Dlso.bib( { id: dlsoref.uuid }, (bibref:any) =>{
                    watcher.next(1);
                    console.log(bibref);
                    let bibId = bibref.work.match(/\/(\d+)$/)?.[1];
                    if(bibId){
                        console.log(bibId);
                        dlsoref.bib = { id: bibId };
                        this.kwApi.Work.get({ id: bibId }, (work: any) => {
                            console.log(work);
                            dlsoref.bib!.title = work.title;
                            dlsoref.bib!.author = work.author;
                            watcher.next(1);
                        } ).$promise.catch( (e:any) => {
                            console.warn(e);
                            dlsoref.bib!.title = 'See source ';
                            return {};
                        })
                    }
                })
               
            })
            
        })
        return watcher;
    }

/**
 * Handle Http operation that failed.
 * Let the app continue.  --  TODO :: move this to core service and expand.
 *
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
